//Libraries
import React, { FunctionComponent, useState, useEffect } from 'react'
import cs from 'classnames'

//Types
import { EventType } from '../z_components-types'

//Styles
import styles from './css/event.module.scss'

export const Event: FunctionComponent<EventType> = ({ data }) => {
  const e = data.node.frontmatter
  const eventHash = e.title.toLowerCase().replaceAll(' ', '-')
  const windowHash = typeof window !== 'undefined' && window.location.hash.substring(1)
  const [isEventOpen, setIsEventOpen] = useState(eventHash === windowHash)

  useEffect( () => {
    const handleHashChange = () => {
      const windowHash = typeof window !== 'undefined' && window.location.hash.substring(1)
      setIsEventOpen(eventHash === windowHash)
    };
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  } )

  const dateConfig: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  }

  const start = new Date(e.start)
  const end = new Date(e.end)
  let endFormatted: string;

  if ( end.getDay() === start.getDay() ) {
    endFormatted = end.toLocaleTimeString('default', {hour: 'numeric', minute: '2-digit'})
  } else {
    endFormatted = end.toLocaleString('default', dateConfig)
  }

  const startFormatted = start.toLocaleString('default', dateConfig)

  const handleClick: () => void = () => {
    setIsEventOpen(!isEventOpen)
  }

  return (
    <article className={styles.event} id={eventHash}>
      <h2
        onClick={handleClick}
        className={cs(styles.title, isEventOpen && styles.open)}
      >
        {e.title}
      </h2>
      {isEventOpen && (
        <>
          <div className={styles.infoLine}>
            <h3 className={styles.label}>When</h3>
            <p className={styles.value}>
              <strong>{startFormatted}</strong> - <strong>{endFormatted}</strong>
            </p>
          </div>
          <div className={styles.infoLine}>
            <h3 className={styles.label}>Where</h3>
            <p className={styles.value}>
              <strong>{e.where}</strong>
            </p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.node.html }}></div>
        </>
      )}
    </article>
  )
}

export default Event
