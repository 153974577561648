//Types
import {
  GetElementHeightType,
  InputFormatType,
  TextareaFormatType,
  CompareDateType,
} from './z_static-types'

//Finds element height by id
export const getElementHeight: GetElementHeightType = id => {
  return document.getElementById(id).clientHeight
}

//Forces phone number input field to a format of 555-555-5555 and strips all non numerical numbers
export const formatPhoneNum: InputFormatType = e => {
  let evt: string = e.target.value.replace(/\D/g, '')
  evt.length > 10 && (evt = evt.slice(0, 10))
  return evt.length >= 7
    ? [
        evt.slice(0, 3),
        '-',
        evt.slice(3, 6),
        '-',
        evt.slice(6, evt.length),
      ].join('')
    : evt.length >= 4
    ? [evt.slice(0, 3), '-', evt.slice(3)].join('')
    : evt
}

//Automatically capitalizes all words in name field
export const capEachWord: InputFormatType = e => {
  const evt: string = e.target.value.replace(/[^\s*a-zA-Z]/g, '')
  let words: string[]
  let capWords: string
  if (evt.length > 0) {
    words = evt.split(' ')
    capWords =
      words.length > 0
        ? words
            .map(
              (item: string) =>
                (item.split('')[0] ? item.split('')[0].toUpperCase() : '') +
                item.slice(1, item.length)
            )
            .join(' ')
        : words.join(' ')
    return capWords
  } else {
    return evt
  }
}

//Automatically capitalizes all words at begining of sentence in message field
export const capFirstWordSentence: TextareaFormatType = e => {
  const evt: string[] = e.target.value.replace(/<[^>]+>/g, '').split('.')
  const words: string[][] = evt.map((item: string) => item.split(''))
  return words
    .map((item: string[]) =>
      item[0] && item[0] !== ' '
        ? item
            .map((i: string, index: number) =>
              index === 0 ? i.toUpperCase() : i
            )
            .join('')
        : item[1]
        ? item
            .map((i: string, index: number) =>
              index === 1 ? i.toUpperCase() : i
            )
            .join('')
        : item
    )
    .join('.')
}

//Compares dates, used to sort an array by a date
export const compareDates: CompareDateType = (date1, date2) => {
  if (Date.parse(date1) <= Date.parse(date2)) {
    return -1
  } else {
    return 1
  }
}

export const removeNonNumbers: InputFormatType = e => {
  return e.target.value.replace(/[^0-9.]/g, "")
}

export const removeSpaces: InputFormatType = e => {
  return e.target.value.replace(/\s/g, '')
}
