//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { EventsTypes, EventType, EventTypeFrontmatter } from './z_page-types'

//Styles
import styles from './css-pages/pages.module.scss'
import './css-pages/calendar.scss';

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWelcome from '../components/header/pageWelcome'
import Event from '../components/templates/event'
import PageBackground from '../components/free/pageBackground'

//Static
import { compareDates } from '../../static/reuseableFunctions'

const localizer = momentLocalizer(moment);

const filterPlaceholder = (event: EventType) => event.node.frontmatter.title !== 'placeholder';

const Events: FunctionComponent<EventsTypes> = ({ data }) => {
  const currentDate = new Date()

  const realEvents = data.allMarkdownRemark.edges.filter(filterPlaceholder);

  const calendarEvents: EventTypeFrontmatter[] = realEvents.map((event: EventType) => {
    return {
      title: event.node.frontmatter.title,
      start: new Date(event.node.frontmatter.start),
      end: new Date(event.node.frontmatter.end),
      where: event.node.frontmatter.where,
    }
  })

  const events = realEvents.filter(
      event =>
        Date.parse(event.node.frontmatter?.end?.toString()) >=
          Date.parse(currentDate.toString())
    )
    .sort((a, b) =>
      compareDates(a.node.frontmatter?.end?.toString(), b.node.frontmatter?.end?.toString())
    )
    .map((event: EventType, index: number) => (
      <Event key={index} data={event} />
    ))

  const linkToEvent = (event: EventTypeFrontmatter) => {
    const { title, end } = event
    if ( typeof window === 'undefined' ) return
    if ( Date.parse( end.toString() ) < Date.parse( currentDate.toString() ) ) return

    const eventHash = title.toLowerCase().replaceAll(' ', '-');
    window.location.hash = eventHash;
  }

  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Events</title>
          <link rel="canonical" href="https://ooa.csi.edu/events/" />
          <meta
            name="description"
            content="See any Events coming up for the Office on Aging."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWelcome
          img={data.markdownRemark.frontmatter.img}
          title={data.markdownRemark.frontmatter.title}
          imgAltText="Events banner image"
        />

        <Calendar
          localizer={localizer}
          defaultDate={ new Date() }
          defaultView='month'
          events={calendarEvents}
          className={'events-calendar'}
          messages={{
            noEventsInRange: 'There are no upcoming events scheduled at this time.'
          }}
          onSelectEvent={(event: EventTypeFrontmatter) => linkToEvent(event)}
        />

        {events.length ? (
          <div className={styles.events}>{events}</div>
        ) : (
          <h2 className={styles.noEvents}>There are no events at this time</h2>
        )}
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default Events

export const eventsQuery = graphql`
  query events {
    markdownRemark(frontmatter: { template_key: { eq: "events-page" } }) {
      frontmatter {
        title
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template_key: { eq: "event" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            start
            where
            end
          }
        }
      }
    }
  }
`
